<script>
import {UBtn, UField, UInput, UAjaxBar, UCheckbox} from 'uloc-vue'
import LoginMixin from '../login.mixin.js'
import Validacao2Fa from "components/auth/Validacao2Fa.vue"

export default {
  name: 'Auth',
  inject: ['appRoot'],
  mixins: [LoginMixin],
  components: {
    Validacao2Fa,
    UAjaxBar,
    UBtn,
    //UCheckbox,
    UField,
    UInput
  },
  props: {},
  data() {
    return {
      loginErrorData: null,
      isSubmiting: false,
      need2FA: false
    }
  },
  beforeCreate() {
    this.lang = this.$uloc ? this.$uloc.i18n.lang : 'pt-BR' // TODO: Desenvolver próprio sistema de línguas do Auth
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    __doLogin() {
      this.$refs.bar && this.$refs.bar.start()
      this.isSubmiting = true
      this.doLogin()
          .then((r) => {
            this.$refs.bar && this.$refs.bar.stop()
            if (r.need2FA) {
              this.need2FA = r
              this.isSubmiting = false
              return
            }
            if (this.$route.query.redirect && this.$route.query.redirect !== '/logout') {
              this.$router.push({path: this.$route.query.redirect})
            } else {
              this.$router.push('/')
            }
            this.isSubmiting = false
          })
          .catch((err) => {
            this.isSubmiting = false
            console.log('ERRO LOGIN')
            console.log('Login error', err)
            this.loginErrorData = err.message || 'Ocorreu algum erro no login'
            this.$refs.bar && this.$refs.bar.stop()
            this.$uloc.notify({
              type: 'negative',
              message: this.loginErrorData
            })
          })
    }
  },
  computed: {
    isLogged: function () {
      return this.$uloc ? this.$uloc.auth.logged : this.$auth.logged
    }
  }
}
</script>

<template>
  <div class="sl-auth" v-if="!isLogged">
    <div class="sl-login animated">
      <div class="login-form">
        <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('erp.login.logo') && $appConfig.getConfig('erp.login.logo') && $appConfig.getConfig('erp.login.logo')" class="login-header">
          <div class="lh-logo full">
            <img :src="$appConfig && $appConfig.getConfig('erp.login.logo')" />
          </div>
<!--          <div class="lh-poslogo">Não tem acesso? <a target="_blank" href="https://www.suporteleiloes.com.br/atendimento">Clique aqui</a></div>-->
        </div>
        <div v-else-if="appRoot.installed && $appConfig" class="login-header">
            <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') === 'enterprise' && $appConfig.isConfigNotEmpty('empresa.logomarca')" class="lh-logo full">
              <a target="_blank"><img :src="$appConfig.getConfig('empresa.logomarca')" /></a>
            </div>
            <div v-else class="lh-logo">
              <a href="https://www.suporteleiloes.com.br" target="_blank"><img src="https://static.suporteleiloes.com.br/assets/logo-black.png" /></a>
            </div>
            <div class="lh-poslogo" v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') !== 'enterprise'">Não tem acesso? <a target="_blank" href="https://www.suporteleiloes.com.br/atendimento">Clique aqui</a></div>
        </div>
        <form v-if="need2FA === false" class="auth-form" method="post" @submit.prevent="__doLogin">
<!--          <div class="m-t sl-alert sl-alert-warning bg-yellow-2">Informamos que estamos concluindo a migração do software para a versão 3.0.0, dentro de 24h terminaremos nossos trabalhos e esta mensagem desaparecerá.</div>-->
          <div class="block m-t-lg"></div>
          <div class="login-form-pre-header">
            <div class="login-lbl">Conectar ao <strong>ERP</strong></div>
            <div class="login-lbl-version" v-if="$globalConfig && $globalConfig.appVersion">v{{ $globalConfig.appVersion }}</div>
            <div class="login-lbl-version" v-else>&nbsp;</div>
          </div>
          <u-field
              :label-width="12"
              class="login-input"
              label="CPF, Login ou e-mail"
          >
            <i class="fa fal fa-user form-icon-float" />
            <u-input required type="text" v-model="user.email" autofocus class="login-input" hide-underline/>
          </u-field>
          <u-field
              :label-width="12"
              label="Senha de acesso"
              class="login-input m-t-md"
          >
            <i class="fa fal fa-key form-icon-float" />
            <u-input required type="password" v-model="user.password" class="login-input" hide-underline/>
          </u-field>

          <u-btn :disabled="isSubmiting" type="submit" size="md"
                 label="Entrar"
                 class="w-full m-t-md btn-login" no-caps />
          <div class="m-t-md text-center">
            <a class="cursor-pointer login-recuperar-senha">Esqueci minha senha</a>
          </div>
        </form>
        <validacao2-fa :auth="need2FA" v-else />
        <div class="login-footer">
          <div class="license text-center">
            <div>Ao usar nosso ERP, você concorda com nossa</div>
            <a href="https://www.suporteleiloes.com.br/politica-privacidade" target="_blank">política de privacidade</a> e <a href="https://www.suporteleiloes.com.br/termos-uso" target="_blank">termos de uso</a>.
          </div>
        </div>
      </div>
    </div>
    <div class="sl-login-banner">
<!--      <img src="@/assets/images/erp-login-img1.png" />-->
    </div>
    <div class="sl-login-bar">
      <ul>
        <li>
          <a href="https://docs.suporteleiloes.com" target="_blank">
            <i class="fal fa-circle-question"></i>
            <span>Ajuda</span>
          </a>
        </li>
        <li>
          <a href="/#/service-status">
            <i class="fal fa-shield-check"></i>
            <span>central de diagnóstico</span>
          </a>
        </li>
        <li>
          <a>
            <i class="fal fa-code-merge"></i>
            <span>central de atualizações</span>
          </a>
        </li>
        <li>
          <a :href="$appConfig.getConfig('cliente.painelUrl')" target="_blank">
            <i class="fal fa-person-sign"></i>
            <span>painel do cliente</span>
          </a>
        </li>
        <li>
          <a :href="$appConfig.getConfig('comitente.url')" target="_blank">>
            <i class="fal fa-person-sign"></i>
            <span>painel do comitente</span>
          </a>
        </li>
        <li v-if="$appConfig.getConfig('site.url')">
          <a :href="$appConfig.getConfig('site.url')" target="_blank">
            <i class="fal fa-browser"></i>
            <span>Site</span>
          </a>
        </li>
      </ul>
    </div>
    <u-ajax-bar ref="bar" color="primary"/>
  </div>
</template>
